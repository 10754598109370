import { Injectable } from '@angular/core';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import type { Voucher } from '@givve/ui-kit/models';

@Injectable({ providedIn: 'root' })
export class VoucherHttpService extends MultiObjectHTTPService<Voucher> {
  memberUri = new URITemplate('{+api_v1_base}/vouchers/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/vouchers');
  searchUri = new URITemplate(`{+api_base}/admin/search/vouchers`);
}
